<template>
<!--Header-->
<div class= "button">
  <div class="mb-20">
    
     <div class= "flex flex-initial">
        <div class="place-items-start fixed top-0 md:top-48 right-0 w-1/2 md:w-1/3 z-02">
          <img src = "/images/newgraph.png">
        </div>
      </div>
    </div>
    </div>
<!--Content-->
<div class= "grid grid-cols-6 gap-0">
  <div class= "box-border mb-3 border-0">
  </div>

<div class ="box-border mb-3 border-2 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center font-sans text-lg text-black scroll:text-pink bg-white">
                <h class="text-xl text-bold text-indigo-900"> Our Community Benefit Statement by <span class="text-indigo-500"> <a href="https://www.realworth.org/">RealWorth </a> </span>
                <br/> <br/></h>
                <p>
                  When looking at the local context for the social impact of Persons Unknown, there are two particularly relevant indicators of inequality in housing. The first being the well documented<span class="text-indigo-500"> <a text-indigo-500 href="https://liverpool.gov.uk/media/1359213/imd-2019-liverpool-analysis-main-report.pdf">high levels of deprivation in Liverpool</a></span>. The second being poor access to the housing market for those not already on the property ladder. For those who have no choice but to rent,<span class="text-indigo-500"> <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/945013/2019-20_EHS_Headline_Report.pdf"> 23% of private rental homes and 12% of socially rented homes fail to meet the decent homes standard </a></span>. 
                <br/> <br/></p>
                <p>
                  Persons Unknown will consist of 30-40 homes ranging in size from one- to four-bedrooms. This combination of household sizes has been designed to suit a range of housing needs and to encourage a more integrated community. The mix of ownership models will prioritise those who otherwise could not access home ownership. The proposal will bring brownfield land back into community use, increase the provision of greenspace in the area and create assets for community use. There are expected economic benefits locally through the business units bringing new businesses into the area. 
                <br/> <br/></p>
                <p>
                The scheme responds to the <span class="text-indigo-500"><a href="https://liverpool.gov.uk/media/1356834/01-local-plan-january-2018-final.pdf">2018 Draft Liverpool Local Plan </a> </span> providing housing that supports the needs of current and future residents; a key priority of the Draft Local Plan. 
                </p>
               </div>

  <div class ="box-border mb-3 border-2 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center font-sans text-lg text-black scroll:text-pink bg-pink">
               <h class="text-xl text-bold text-indigo-900"> Why 'Housing Co-operative'?
                <br/> <br/></h>
                <p>    <!-- on scroll turn text tex-grey-700 -->
               Housing cooperatives are not-for-profit ‘registered societies’. They allow people to control their own housing, without actually owning it personally. Instead, the members or tenants govern the co-operatives activities. With a fully-mutual par-value co-op such as ours, all tenants are members and all members need to be tenants or prospective tenants. 
                 <br/> <br/></p>
                 <p> Housing co-ops facilitate grassroots control over housing, providing rented homes where the tenants are (collectively) their own landlord. The organisation is run according to co-operative principles, giving everyone an equal say and an equal responsibility in the management of housing. 
                <br/> <br/></p>
                 <p>
                   Self-management has the additional benefit of allowing us to provide housing in an affordable way, while also having the autonomy to make improvements not only reactively but also proactively. Finances can be planned with a mind to protect against excessive rent rises while allowing for a long-term approach to maintenance. Should the co-op cease to exist, its assets have to stay within the co-operative movement; they cannot pass to the members or be used to invest outside the co-operative movement – our primary (and legally binding) rules of association ensure that <span class="text-indigo-500"> <a href="www.radicalroutes.org.uk"> Radical Routes</a></span> will take over our assets should our co-operative wind up at any point. Thus, by coming together and acting cooperatively, housing remains in mutual ownership. 
                <br/> <br/></p> 
                <p>
                  By setting up a housing co-op, we aim to empower ourselves to take control of one of the most fundamental aspects of our lives and in the process transfer property from private to mutual ownership. We aim to provide a safe and secure environment for people to live in for generations to come
                <br/> <br/></p>
                </div>

               
  
  
     
</div>
</template>

<script>
import {onMounted, ref} from "vue";


export default {
  setup() {

      let messageYou = ref("innit empty")


    function restart() {
      window.location.reload()
    }

    onMounted(() => {
        console.log(messageYou)
    })


    return {
      messageYou,
      restart
    }
  }
}
</script>

<style scoped>

</style>